import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ImgContainer } from "./AutomaticDoor"

const FinishImages = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { dir: { regex: "/images/finishes/preview/" } }) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                    blurredOptions: { width: 100 }
                    placeholder: BLURRED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      `}
      render={data =>
        data.allFile.edges.map(({ node }, index) => (
          <ImgContainer>
            <GatsbyImage
              key={index}
              image={getImage(node.childImageSharp.gatsbyImageData)}
            ></GatsbyImage>
          </ImgContainer>
        ))
      }
    />
  )
}

export default FinishImages
