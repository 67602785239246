import React from "react"
import Section from "../../styles/styled-component/Section"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import { FinishContainer } from "./luxuryComponents"
import { ButtonWrapper, Button } from "../../styles/styled-component/Button"
import FinishImages from "./finishesImages"
import { Trans } from "gatsby-plugin-react-i18next"
const Finishes = () => {
  return (
    <Section flex center minHeight spaceBottom>
      <Container flex center>
        <InnerContainer half>
          <h1>
            <Trans>Standard Finishes</Trans>
          </h1>
          <p>
            <Trans>
              Style your divas lift with high quality modern finishes
            </Trans>
          </p>
          <p>
            <Trans>
              Made in Italy, these finishes are made to last and easy to clean
            </Trans>
          </p>

          <ButtonWrapper>
            <Button
              to="/finishes"
              bgColor="#0066ff"
              bgColorHover="#005ce6"
              borderRadius="24px"
            >
              <Trans>Check out all Finishes</Trans>{" "}
            </Button>
          </ButtonWrapper>
        </InnerContainer>
        <InnerContainer half>
          {" "}
          <FinishContainer>
            <FinishImages />
          </FinishContainer>
          {/* 
          <ImageContainer>
            <StaticImage
              height={500}
              src="../../images/BgkDivas.png"
              placeholder="blurred"
            ></StaticImage>
          </ImageContainer> */}
        </InnerContainer>
      </Container>
      <Container flex center>
        <InnerContainer></InnerContainer>
      </Container>
    </Section>
  )
}

export default Finishes
