import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import { DisplayContainer, DoorContainer, ImgContainer } from "./AutomaticDoor"
import AutomaticDoor from "./AutomaticDoor"
import ManualDoor from "./manualDoor"
import Display from "./display"
import Finishes from "./finishes"
import Ceiling from "./ceiling"
import { CeilingContainer } from "./luxuryComponents"
import Handrail from "./handrail"
import { Trans } from "gatsby-plugin-react-i18next"
const DesignSection = () => {
  return (
    <>
      <Finishes></Finishes>
      <Section>
        <Container>
          <InnerContainer>
            <h3>
              <Trans>Ceiling Finishes</Trans>
            </h3>
            <p>
              <Trans>
                Light up your elevator with our suspended ceiling, powered by
                led techonology, find the design that suits you the most
              </Trans>{" "}
            </p>
            <CeilingContainer>
              <Ceiling />
            </CeilingContainer>
          </InnerContainer>
        </Container>
      </Section>
      <Section flex center>
        <Container>
          <Container>
            <InnerContainer>
              <h3>
                <Trans>Automatic doors</Trans>
              </h3>

              <DoorContainer>
                <AutomaticDoor></AutomaticDoor>
              </DoorContainer>
            </InnerContainer>
          </Container>
          <Container>
            <InnerContainer>
              <h3>
                <Trans>Manual Swing doors</Trans>
              </h3>

              <DoorContainer>
                <ManualDoor></ManualDoor>
              </DoorContainer>
            </InnerContainer>
          </Container>
        </Container>
      </Section>
      <Section flex center bgColor="#f2f2f2">
        <Container flex center>
          <InnerContainer>
            <h2>
              <Trans>Operating panels</Trans>
            </h2>
            <p>
              <Trans>
                Fit your elevator with beautiful high quality steel operating
                panels, fitted with Movilift's TFT display, for a complete
                customization experiece.
              </Trans>
            </p>
          </InnerContainer>
        </Container>
        <Container flex center spaceBottom>
          <InnerContainer half>
            <h3>
              <Trans>Cabin Column</Trans>
            </h3>
            <p>
              <Trans>
                A full height stainless steel operating panel to give your cabin
                a stylish and notable look, customize the display with the
                content of your choice
              </Trans>
            </p>
          </InnerContainer>
          <InnerContainer half>
            <ImgContainer>
              <StaticImage
                height={450}
                src="../../images/carcabincolumn.png"
                placeholder="blurred"
              />
            </ImgContainer>
          </InnerContainer>
        </Container>
        <Container flex center>
          <InnerContainer half>
            <DisplayContainer>
              <Display />
            </DisplayContainer>
          </InnerContainer>{" "}
          <InnerContainer half>
            <h3>
              <Trans>Landing operating panel</Trans>
            </h3>
            <p>
              <Trans>
                Fit your landing floor with our LOPs, choose the model that fits
                your house looks the most, and choose between a LCD or TFT
                display for a complete customization options
              </Trans>{" "}
            </p>
          </InnerContainer>
        </Container>
        <Container flex center>
          <InnerContainer>
            <h3>
              <Trans>Marlin Evo</Trans>
            </h3>
            <p>
              <Trans>
                Make your elevator experiece unique, customize the elevator
                displays with your own images and texts
              </Trans>
            </p>
          </InnerContainer>
          <InnerContainer>
            <ImgContainer>
              <StaticImage
                src="../../images/marlinevo.png"
                placeholder="blurred"
              />
            </ImgContainer>
          </InnerContainer>
        </Container>
      </Section>
      <Section minHeight>
        <Container>
          <InnerContainer>
            <h3>
              <Trans>Handrails</Trans>
            </h3>
            <p>
              <Trans>Available in different finishes, round or squared</Trans>
            </p>
            <CeilingContainer>
              <Handrail />
            </CeilingContainer>
          </InnerContainer>
        </Container>
      </Section>
    </>
  )
}

export default DesignSection
