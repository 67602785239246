import React from "react"
import Hero from "../components/design/hero"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DesignSection from "../components/design/design"

import { graphql } from "gatsby"
const Design = ({ data }) => {
  return (
    <Layout>
      <Seo title="Design your home lift divas" />
      <Hero />
      <DesignSection />
    </Layout>
  )
}

export default Design

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
