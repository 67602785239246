import React from "react"

import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ImgContainer, Label } from "./AutomaticDoor"

const Display = () => {
  const displays = ["Makalu", "Europa", "Flat"]
  const displayLabel = displays.map(display => display)

  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { name: { regex: "/LOP/" } }) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                    blurredOptions: { width: 100 }
                    placeholder: BLURRED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      `}
      render={data =>
        data.allFile.edges.map(({ node }, index) => (
          <ImgContainer>
            <GatsbyImage
              key={index}
              image={getImage(node.childImageSharp.gatsbyImageData)}
            ></GatsbyImage>
            <Label>{displayLabel[index]}</Label>
          </ImgContainer>
        ))
      }
    />
  )
}
export default Display
