import React from "react"
import { useState } from "react"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Container from "../../styles/styled-component/Container"
import bgImage from "../../images/home-lift-divas-bg.jpg"

import { Trans } from "gatsby-plugin-react-i18next"
import {
  Button,
  ArrowForward,
  ArrowRight,
  SecondaryButton,
  ButtonsContainer,
} from "../../styles/styled-component/Button"
import { LuxurySection } from "../luxury/luxuryComponents"
const Hero = () => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(!hover)
  }
  return (
    <>
      <LuxurySection flex bgImage={bgImage}>
        <Container>
          <InnerContainer>
            {" "}
            <h1>
              <Trans>Design your home lift</Trans>
            </h1>
            <p>
              <Trans>
                Style your home lift with high quality and luxurious materials,
                made by artisans to give that high quality feel for your
                elevator
              </Trans>
            </p>
            <ButtonsContainer>
              <Button
                to="/luxury-design"
                bgColor="#0066ff"
                bgColorHover="#005ce6"
                borderRadius="24px"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              >
                <Trans>Luxury solutions</Trans>
                {hover ? <ArrowForward /> : <ArrowRight />}
              </Button>{" "}
              <SecondaryButton color="#fff" to="/gallery" borderRadius="24px">
                <Trans>Gallery</Trans>
              </SecondaryButton>
            </ButtonsContainer>
          </InnerContainer>
        </Container>
      </LuxurySection>
    </>
  )
}

export default Hero
