import { StaticQuery } from "gatsby"
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ImgContainer } from "./AutomaticDoor"
import { CeilingLabel } from "./luxuryComponents"

const Ceiling = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allFile(
            filter: { dir: { regex: "/images/ceiling/" } }
            sort: { fields: name }
          ) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, height: 200, width: 300)
                }
                name
              }
            }
          }
        }
      `}
      render={data =>
        data.allFile.edges.map(({ node }, index) => {
          var nodeName = node.name
          var formattedName = nodeName.replace(/-/g, " ")

          return (
            <div>
              <ImgContainer>
                <GatsbyImage
                  imgStyle={{ objectFit: `contain`, objectPosition: "50% 50%" }}
                  key={index}
                  alt={formattedName}
                  image={getImage(node.childImageSharp.gatsbyImageData)}
                />
              </ImgContainer>{" "}
              <CeilingLabel> {formattedName}</CeilingLabel>
            </div>
          )
        })
      }
    />
  )
}

export default Ceiling
