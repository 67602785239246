import React from "react"

import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { ImgContainer } from "./AutomaticDoor"
const Label = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
`
const ManualDoor = () => {
  const automaticDoors = ["Blind", "Panoramic", "Standard", "Aluminum"]
  const doorLabel = automaticDoors.map(door => door)

  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { name: { regex: "/MD/" } }) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                    height: 400
                    blurredOptions: { width: 100 }
                    placeholder: BLURRED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      `}
      render={data =>
        data.allFile.edges.map(({ node }, index) => (
          <ImgContainer>
            <GatsbyImage
              key={index}
              alt={doorLabel[index]}
              image={getImage(node.childImageSharp.gatsbyImageData)}
            ></GatsbyImage>

            <Label>{doorLabel[index]}</Label>
          </ImgContainer>
        ))
      }
    />
  )
}

export default ManualDoor
